import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useAuth } from "../../Context/AuthContext";

const AllReferall = ({ activeTab }) => {
  const [allreferral, setAllreferral] = useState([]);
  const [startDate, setStartDate] = useState(""); // State for start date
  const [endDate, setEndDate] = useState(""); // State for end date
  const [medium, setMedium] = useState(""); // State for medium
  const [campaign, setCampaign] = useState(""); // State for campaign
  const [program, setProgram] = useState(""); // State for program
  const [status, setStatus] = useState(""); // State for status
  const [email, setEmail] = useState(""); // Added state for email search
  const [pagination, setPagination] = useState({});
  const { token } = useAuth();

  const convertToCSV = (data) => {
    if (!data || !data.length) {
      return '';
    }

    const headers = Object.keys(data[0]);
    const rows = data.map(item =>
      headers.map(header => JSON.stringify(item[header], (key, value) => value === null ? '' : value)).join(',')
    );

    return [headers.join(','), ...rows].join('\n');
  };

  const downloadCSV = (data) => {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'referrals.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  const getallReferral = async () => {
    try {
      const resp = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/referrals/all-data`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (resp.status === 200) {
        downloadCSV(resp.data.referral)
      }
    } catch (error) {
      console.log("Error fetching referrals:", error);
    }
  };


  const AllReferallData = useCallback(async () => {
    try {
      const params = new URLSearchParams();

      if (startDate) params.append("startDate", startDate);
      if (endDate) params.append("endDate", endDate);
      if (medium) params.append("medium", medium);
      if (campaign) params.append("campaign", campaign);
      if (program) params.append("program", program.toLowerCase());
      if (status) params.append("status", status.toLowerCase());
      if (email) params.append("email", email);

      // Pagination params
      params.append("page", pagination.page || 1);
      params.append("limit", 10);

      const queryString = params.toString() ? `?${params.toString()}` : "";

      const resp = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/referrals${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (resp.status === 200) {
        setAllreferral(resp.data.referrals);
        setPagination(resp.data.pagination);
      }
    } catch (error) {
      console.log("Error fetching referrals:", error);
    }
  }, [
    token,
    startDate,
    endDate,
    medium,
    campaign,
    program,
    status,
    email,
    pagination.page,
  ]);

  useEffect(() => {
    AllReferallData();
  }, [AllReferallData]);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") setStartDate(value);
    if (name === "endDate") setEndDate(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "medium") setMedium(value);
    if (name === "campaign") setCampaign(value);
  };

  const handleProgramChange = (e) => {
    setProgram(e.target.value); // Update program state
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value); // Update status state
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    AllReferallData();
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value); // Update email state
  };

  const clearData = () => {
    setStartDate("");
    setEndDate("");
    setMedium("");
    setCampaign("");
    setProgram("");
    setStatus("");
    setEmail("");
    setPagination(1)
  };

  const handleNextPage = (e) => {
    e.preventDefault();
    if (pagination.page < pagination.totalPages) {
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  const handlePrevPage = (e) => {
    e.preventDefault();
    if (pagination.page > 1) {
      setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Options for formatting date
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true, // To format in 12-hour time
    };

    return date.toLocaleDateString('en-US', options);
  }


  return (
    <div>
      {activeTab === 2 && (
        <div>
          <form onSubmit={handleSubmit}>
            <div className="flex justify-end gap-x-8 p-4">
              <div
                className="text-red-600 text-xs cursor-pointer border-r-[1px] pr-4"
                onClick={clearData}
              >
                Clear all
              </div>
              {/* <button
                className="text-red-600 text-xs cursor-pointer"
                type="submit"// Trigger filter on submit
              >
                Submit
              </button> */}
            </div>
            <div className="overflow-x-auto flex">
              <div className="border-r-[1px] w-[20rem]">
                <div className="flex">
                  <div className="flex px-4">
                    <div className="pr-2">
                      <div className="text-start">Referrals list</div>
                      <div className="text-start text-sm text-[#b0b4ba]">
                        List of all referrals <br /> submitted by users <br />{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="Search by email.."
                  className="py-2 my-4 border-[1px] ml-0 w-[90%] px-4"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              {/* filter */}
              <div className="w-[20rem] border-r-[1px]">
                <div className="text-start ml-2 text-[#6b7280] text-md">
                  Filters
                </div>
                <div className="text-start ml-2 text-sm mt-8">by Date</div>
                <div className="flex gap-x-4">
                  <div className="pl-2">
                    <div className="text-start">Start</div>
                    <input
                      type="date"
                      name="startDate"
                      value={startDate}
                      onChange={handleDateChange}
                      className="border-[1px]"
                    />
                  </div>
                  <div className="">
                    <div className="text-start">End</div>
                    <input
                      type="date"
                      name="endDate"
                      value={endDate}
                      onChange={handleDateChange}
                      className="border-[1px]"
                    />
                  </div>
                </div>
              </div>
              {/* filter */}
              {/* registration campaign */}
              <div className="w-[13rem] text-start ml-2 border-r-[1px] px-3">
                <div className="text-sm mt-10">by Registration campaign</div>
                <div className="flex gap-x-4">
                  <div className="pt-4">
                    <div>Medium</div>
                    <input
                      type="text"
                      name="medium"
                      value={medium}
                      onChange={handleInputChange}
                      className="border-[1px] w-[100%]"
                    />
                  </div>
                  <div className="pt-4">
                    <div>Campaign</div>
                    <input
                      type="text"
                      name="campaign"
                      value={campaign}
                      onChange={handleInputChange}
                      className="border-[1px] w-[100%]"
                    />
                  </div>
                </div>
              </div>
              {/* registration campaign */}
              {/* program */}
              <div className="w-[8rem] text-start ml-2 border-r-[1px] px-3">
                <div className="mt-10">by Program</div>
                <select
                  id="options"
                  name="options"
                  className="mt-4 p-1 w-[6rem]"
                  value={program}
                  onChange={handleProgramChange}
                >
                  <option value="" disabled >
                    Select programs
                  </option>
                  <option value="MBA">MBA</option>
                  <option value="MCA">MCA</option>
                  <option value="M.COM">M.COM</option>
                  <option value="MA">MA</option>
                  <option value="BBA">BBA</option>
                  <option value="BCA">BCA</option>
                  <option value="B.COM">B.COM</option>
                </select>
              </div>
              {/* program */}
              {/* by Status */}
              <div className="w-[10rem] text-start ml-2 border-r-[1px] px-3">
                <div className="mt-10">by Status</div>
                <select
                  id="options"
                  name="options"
                  className="mt-4 p-2"
                  value={status}
                  onChange={handleStatusChange}
                >
                  <option value="" disabled >
                    Status
                  </option>
                  <option value="PENDING">PENDING</option>
                  <option value="ENROLLED">ENROLLED</option>
                </select>
              </div>
              {/* by Status */}
              {/* by Page */}
              <div className="w-[8rem] text-start ml-2 px-3">
                <div className="flex gap-x-8 mt-16">
                  <div className="bg-red-500 text-center text-white text-sm rounded-lg p-1 cursor-pointer" onClick={() => getallReferral()}>
                    Download Csv
                  </div>
                </div>
              </div>
              {/* by Page */}
            </div>
          </form>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr>
                  <th className="px-4 py-2 border">#</th>
                  <th className="px-4 py-2 border">Referer</th>
                  <th className="px-4 py-2 border">Referee</th>
                  <th className="px-4 py-2 border">Assigned to</th>
                  <th className="px-4 py-2 border">Registration campaign</th>
                  <th className="px-4 py-2 border">Status</th>
                  <th className="px-4 py-2 border">Added on</th>
                  <th className="px-4 py-2 border">Programs</th>
                </tr>
              </thead>
              <tbody>
                {allreferral.map((referral, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2 border">{index + 1}</td>
                    <td className="px-4 py-2 border">
                      {referral?.referrer?.first_name} {" "}
                      {/* <strong className="text-xs cursor-pointer">
                        View all
                      </strong> */}
                      <br />
                      {referral?.referrer?.user_type} {referral?.referrer?.email}
                    </td>
                    <td className="px-4 py-2 border">
                      {referral?.candidate_name}
                      <br />
                      {referral?.candidate_email}
                    </td>
                    <td className="px-4 py-2 border">{referral?.assigned_to}</td>
                    <td className="px-4 py-2 border">{referral?.campaign}</td>
                    <td className="px-4 py-2 border">{referral?.status}</td>
                    <td className="px-4 py-2 border">
                      {formatDate(referral?.created_at)}
                    </td>
                    <td className="px-4 py-2 border">{referral?.candidate_program}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="flex justify-center gap-x-12 items-center mt-4">
              <button
                onClick={handlePrevPage}
                disabled={pagination.page === 1}
                className={`py-2 px-4 border rounded ${pagination.page === 1
                  ? "bg-gray-200"
                  : "bg-blue-500 text-white"
                  }`}
              >
                Previous
              </button>
              <span className="text-sm">
                Page {pagination.page} of {pagination.totalPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={pagination.referrals}
                className={`py-2 px-4 border rounded ${pagination.page === pagination.totalPages
                  ? "bg-gray-200"
                  : "bg-blue-500 text-white"
                  }`}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllReferall;

