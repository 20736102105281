import React, { useState, useEffect } from "react";
import logo from "../assets/vignan_online.png";
import axios from "axios";
import { useAuth } from "../Context/AuthContext.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import { useCampaign } from '../Context/CampaignContext.jsx';

const Login = () => {
  const [otpStatus, setOtpStatus] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [emailSuccessMessage, setEmailSuccessMessage] = useState("");
  const [resendOtpDisabled, setResendOtpDisabled] = useState(true); // Manage resend OTP button state
  const [countdown, setCountdown] = useState(60); // Timer for resend OTP
  const [error, setError] = useState("");
  const [usererror, setUsererror] = useState("");
  const [toggle, setToggle] = useState(false);
  const [phone, setPhone] = useState("");
  const [token, setToken] = useState("");
  const [tempuser, setTempuser] = useState([]);
  const [alertShown, setAlertShown] = useState(false);
  const { setUser, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { saveCampaignData } = useCampaign();
  const { utm_campaign, utm_source, utm_medium } = location.state || {};

  const getQueryParams = (key) => {
    const params = new URLSearchParams(location.search);
    return params.get(key);
  };
  
  const campaign = getQueryParams("utm_campaign") || utm_campaign || "NA";
  const source = getQueryParams("utm_source") || utm_source ||  "NA";
  const medium = getQueryParams("utm_medium") || utm_medium || "NA"

  saveCampaignData(source, campaign, medium);

  useEffect(() => {
    const { utm_campaign, utm_source, utm_medium } = location.state || {};
    
    // If UTM data is present in the location state, save it to campaign context
    if (utm_campaign || utm_source || utm_medium) {
      saveCampaignData(utm_source || "NA", utm_campaign || "NA", utm_medium || "NA");
    }
  }, [location.state, saveCampaignData]);

  // Effect to handle the countdown timer
  useEffect(() => {
    let timer;
    if (resendOtpDisabled && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      setResendOtpDisabled(false); // Re-enable the resend OTP button after countdown finishes
    }

    return () => clearTimeout(timer); // Cleanup the timer when component unmounts or countdown changes
  }, [countdown, resendOtpDisabled]);

  // Send OTP function
  async function sentOtp(e) {
    e.preventDefault();
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/users/get-otp`, {
        email,
      })
      .then((resp) => {
        if (resp.status === 201) {
          setEmailSuccessMessage(resp.data.status);
          setTimeout(() => {
            setEmailSuccessMessage("");
          }, 2000);
          setOtpStatus(true);
          setResendOtpDisabled(true); // Disable resend OTP button
          setCountdown(60); // Reset countdown for 1 minute
        }
      })
      .catch((error) => {
        console.log(error);
        setUsererror(error.response.data.message);
        setTimeout(() => {
          setUsererror("");
        }, 2000);
      });
  }

  // Login function
  async function login(e) {
    e.preventDefault()
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/users/validate-otp`, {
        email,
        otp,
      })
      .then((resp) => {
        console.log(resp, "response");
        if (resp.status === 200) {
          setToken(resp.data.token)
          setTempuser(resp.data.userInfo)
          const receivedToken = resp.data.token;
          const receivedUserInfo = resp.data.userInfo;
          if (receivedUserInfo.phone_number !== null) {
            setUser(receivedUserInfo);
            localStorage.setItem("token", receivedToken);
            const redirectTo = location.state?.from?.pathname ?? (receivedUserInfo.user_type === "Admin" ? "/sales" : "/student");
            navigate(redirectTo, { replace: true, });
          } else {
            setToggle(true)
          }
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  }

  const updatePhoneNumber = async (e) => {
    e.preventDefault()
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/update-phone`, {
      phone_number: phone,
      id: tempuser.id
    }).then((resp) => {
      localStorage.setItem("token", token);
      setUser(tempuser);
      if (resp.status === 201) {
        const redirectTo = location.state?.from?.pathname ?? (tempuser.user_type === "Admin" ? "/sales" : "/student");
        navigate(redirectTo, { replace: true, });

      }
    }).catch((error) => {
      console.log(error)
    })
  }

  return (
    <div className="max-w-7xl m-auto bg-[#f9fafb]">
      {!toggle && <div>
        <a href="/" className="cursor-pointer">
          <div className="m-auto w-max mt-8">
            <img src={logo} alt="logo" />
          </div>
        </a>
        <div className="text-2xl my-4">Login to your dashboard</div>
        <div className="bg-white w-[23rem] m-auto rounded-lg shadow-xl">
          <form className="pb-2" onSubmit={sentOtp}>
            <div className="text-start px-8 py-4">Email</div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border pl-2 w-[20rem] py-2 rounded-lg outline-none"
              placeholder="Enter email..."
              required
            />
            {!otpStatus && (
              <button
                className="bg-red-500 my-6 p-2 rounded-lg w-[20rem] m-auto cursor-pointer"
                type="submit"
              >
                Send OTP
              </button>
            )}
            {usererror !== "" && <div className="text-red-500">{usererror}</div>}
          </form>
          {emailSuccessMessage && (
            <div className="text-sm">{emailSuccessMessage}</div>
          )}
          {otpStatus && (
            <form className="pb-4" onSubmit={login}>
              <div className="text-start px-8">One-time password</div>
              <input
                type="text"
                className="border w-[20rem] py-2 rounded-lg m-auto outline-none pl-2"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
              <button
                className="bg-red-500 my-6 p-2 rounded-lg w-[20rem] m-auto cursor-pointer font-bold"
                type="submit"
              >
                Log In
              </button>
              <button
                className="bg-red-500  p-2 rounded-lg w-[20rem] m-auto cursor-pointer font-bold"
                type="submit"
                onClick={sentOtp} // Reuse the sentOtp function for resending
                disabled={resendOtpDisabled} // Disable button when necessary
              >
                {resendOtpDisabled ? `Resend OTP in ${countdown}s` : "Resend OTP"}
              </button>
            </form>
          )}
          {error !== "" && <div className="text-red-500">{error}</div>}
        </div>
        <div className="text-xs my-6 text-[#818894]">
          Copyright VIGNAN Online (Deemed to be University). All Rights Reserved.
        </div>
      </div>}
      {toggle && <div className="py-10">
        <a href="/" className="cursor-pointer">
          <div className="m-auto w-max mt-8">
            <img src={logo} alt="logo" />
          </div>
        </a>
        <div className="bg-white w-[23rem] m-auto rounded-lg shadow-xl mt-8">
          <form className="pb-2" onSubmit={updatePhoneNumber}>
            <div className="text-start px-8 py-4">phone</div>
            <input
              type="text"
              value={phone}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d{0,10}$/.test(value)) {
                  setPhone(value);
                }
                setAlertShown(false); // Reset alert flag when input changes
              }}
              onBlur={() => {
                if (phone.length !== 10 && !alertShown) {
                  alert("Phone number must be exactly 10 digits");
                  setPhone(""); // Optionally clear the input or handle accordingly
                  setAlertShown(true); // Set flag to true to prevent multiple alerts
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && phone.length !== 10 && !alertShown) {
                  alert("Phone number must be exactly 10 digits");
                  setPhone("");
                  setAlertShown(true); // Set flag to true to prevent multiple alerts
                }
              }}
              className="border pl-2 w-[20rem] py-2 rounded-lg outline-none"
              placeholder="Enter phone number"
              maxLength={10}
              required
            />

            <button
              className="bg-red-500 my-6 p-2 rounded-lg w-[20rem] m-auto cursor-pointer"
              type="submit"
            >
              Submit Phone Number
            </button>
            <div className="text-md text-red-500">You will receive an OTP while redeeming points.</div>
          </form>
        </div>
      </div>}
    </div>
  );
};

export default Login;
