import React from "react";

const Analytics = ({ activeTab, dashboardata }) => {
  return (
    <div>
      {activeTab === 1 && dashboardata && (
        <div className="max-w-7xl m-auto">
          <div className="text-start px-4 py-4">
            <div>Analytics</div>
            <div className="text-sm text-[#838a94]">
              View analytics such as total referrals,daily referrals generated
            </div>
          </div>

          <div className="flex flex-wrap gap-x-8 gap-y-8 p-4">
            <div className="w-[18rem] h-[9rem] bg-[#004f6b] rounded-lg shadow-lg">
              <div className="w-max text-start p-2 text-white text-xl">
                Total Referrals
              </div>
              <div className="text-right mt-10 pr-4 text-white text-2xl">
                {dashboardata.totalReferral}
              </div>
            </div>
            <div className="w-[18rem] h-[9rem] bg-[#15803d] rounded-lg shadow-lg">
              <div className="w-max text-start p-2 text-white text-xl">
                Total enrolled
              </div>
              <div className="text-right mt-10 pr-4 text-white text-2xl">
                {dashboardata.totalEnrolledCount}
              </div>
            </div>
            {/* <div className="w-[18rem] h-[9rem] bg-[#f3f4f6] rounded-lg shadow-lg">
              <div className="w-max text-start p-2  text-xl">
                Voucher benefits
              </div>
              <div className="text-start pl-4 text-[#797f8c]">
                Marked Enrolled <span className="pl-8 text-black">623</span>
              </div>
              <div className="text-start pl-4 text-[#797f8c]">
                Pending Benefits Trf <span className="pl-8 text-black">22</span>
              </div>
              <div className="text-start pl-4 text-[#797f8c]">
                Benefits Transferred <span className="pl-8 text-black">22</span>
              </div>
              <div className="text-right  pr-4 mt-[-20px]  text-2xl">623</div>
            </div> */}
            <div className="w-[18rem] h-[9rem] bg-[#f3f4f6] rounded-lg shadow-lg">
              <div className="w-max text-start p-2  text-xl">In Progress</div>
              <div className="text-start pl-4 text-[#797f8c]">
                FEE PAID <span className="pl-8 text-black">0</span>
              </div>
              <div className="text-start pl-4 text-[#797f8c]">
                Doc. Verified <span className="pl-8 text-black">0</span>
              </div>
              <div className="text-start pl-4 text-[#797f8c]">
                Other{" "}
                <span className="pl-8 text-black">
                  {dashboardata.totalPendingCount}
                </span>
              </div>
              <div className="text-right  pr-4 mt-[-20px]  text-2xl">
                {dashboardata.totalPendingCount}
              </div>
            </div>
            <div className="w-[18rem] h-[9rem] bg-[#f3f4f6] rounded-lg shadow-lg">
              <div className="w-max text-start p-2  text-xl">Last 7 days</div>
              <div className="text-xs text-start  pl-4">
                Count of referrals generated in last 7 days
              </div>
              <div className="text-right mt-10 pr-4  text-2xl">
                {dashboardata.countLast7DaysReferral}
              </div>
            </div>
            <div className="w-[18rem] h-[9rem] bg-[#f3f4f6] rounded-lg shadow-lg">
              <div className="w-max text-start p-2  text-xl">Last 30 days</div>
              <div className="text-xs text-start  pl-4">
                Count of referrals generated in last 30 days
              </div>
              <div className="text-right mt-10 pr-4  text-2xl">
                {dashboardata.countLast30DaysReferral}
              </div>
            </div>
          </div>
          {/* Third section*/}
          <div className="border-b-[1px] border-[#f3f4f6] border-t-[1px]">
            <div className="flex py-4 ">
              <div className="p-4 w-[50%] border-r-[1px] border-[#f3f4f6]">
                <div className="text-start text-[#374151]">
                  Number of referrals based on program
                </div>
                {dashboardata?.referralByProgram?.length > 0 ? (
                  dashboardata.referralByProgram.map((item, index) => (
                    <div
                      className="flex justify-between pt-4 border-b-[1px]"
                      key={index}
                    >
                      <div>{item.program}</div>
                      <div>{item.count}</div>
                    </div>
                  ))
                ) : (
                  <div>No data available</div>
                )}
              </div>
              <div className="w-[50%]">
                <div className="mt-12">
                  <div className="text-xl">No Data Available</div>
                  <div className="text-xs">
                    We could not find any data for selected dates, try changing
                    date range and submit
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Third section*/}
          {/* foruth section */}
          <div className="flex">
            <div className="w-[50%] border-r-[1px] border-[#f3f4f6]">
              <div className="flex p-4 gap-x-6">
                <div>
                  Top <br /> mediums <br /> (#12)
                </div>
              </div>
              <div className=" w-[100%] overflow-x-auto px-4">
                <table className="w-[100%] border-collapse">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Medium</th>
                      <th>Total</th>
                      <th>Qualified</th>
                      <th>Benefits transferred</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardata?.top10MediumLast30Days?.length > 0 ? (
                      dashboardata.top10MediumLast30Days.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.medium === null ? "null" : item.medium}</td>
                          <td>{item.count}</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" style={{ textAlign: "center" }}>
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="w-[50%] pb-4">
              <div className="flex p-4 gap-x-2">
                <div>
                  Top campaigns in the last <br />
                  30 days (#13)
                </div>
              </div>
              <div className=" w-[100%] overflow-x-auto px-4">
                <table className="w-[100%] border-collapse">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Medium</th>
                      <th>Total</th>
                      <th>Qualified</th>
                      <th>Benefits transferred</th>
                    </tr>
                  </thead>
                  <tbody>
                  {dashboardata?.top10CampaignLast30Days?.length > 0 ? (
                      dashboardata.top10CampaignLast30Days.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.campaign === null ? "null" : item.campaign}</td>
                          <td>{item.count}</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" style={{ textAlign: "center" }}>
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* foruth section */}
          <div className="text-xs py-8 ">Made Possible by VIGNAN Online</div>
        </div>
      )}
    </div>
  );
};

export default Analytics;
